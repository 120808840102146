/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// exports.shouldUpdateScroll = () => {
//   if (window.__navigatingToLink === true) {
//     return [0, 0];
//   }
//   return true;
// };
//
// exports.onRouteUpdate = () => {
//   window.__navigatingToLink = false;
// };

// export const replaceRouterComponent = ({ history }) => {
//
//   const ConnectedRouterWrapper = ({ children }) => (
//     <ContextProvider>
//       <Router history={history}>
//         {children}
//       </Router>
//     </ContextProvider>
//   );
//
//   return ConnectedRouterWrapper;
// }

import React from 'react'
import AppProvider from './src/store/provider'

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}
