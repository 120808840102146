import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from './createContext'
import moment from 'moment'

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    dark: false,
  }

  toggleDarkMode = () => {
    this.setState({ dark: !this.state.dark })
  }

  componentDidMount = () => {
    this.setState({
      dark: !moment(moment(), 'HH:mm:ss').isBetween(
        moment('06:00:00', 'HH:mm:ss'),
        moment('23:00:00', 'HH:mm:ss')
      ),
    })
  }

  render() {
    return (
      <Provider value={{ ...this.state, toggleDarkMode: this.toggleDarkMode }}>
        {this.props.children}
      </Provider>
    )
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppProvider
