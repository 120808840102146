module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2000},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pioneer Design","short_name":"pioneer","start_url":"/","background_color":"#EF463B","theme_color":"#EF463B","display":"minimal-ui","icon":"src/favicon/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2e480ee023c79f2d3ab2dd0a4eec9b9b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121813241-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/admin/**"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"526267958175141"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
